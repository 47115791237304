// Libs
import { blue, grey } from '@ant-design/colors'

// General
export const APP_NAME = 'Be Paid Early'
export const ENV_DEV_NAME = 'development'
export const TOKEN_KEY_NAME = 'token'
export const TABLE_BOTTOM_GAP = 280
export const TABLE_CARD_BOTTOM_GAP = 100
export const APP_REQUEST_TIMEOUT = 60000
export const APP_REQUEST_WAIT_MSG_TIMEOUT = 5000
export const APP_REQUEST_WAIT_MSG_KEY = 'user-message-key'
export const APP_REQUEST_WAIT_MSG =
  'BePaidEarly.com is taking longer than usual to load, please wait up to 30 seconds to try again'

// Colors
export const COLOR_BLUE = blue[5]
export const COLOR_GREY = grey[1]

// User
export const API_AUTH = '/v1/rest-auth/login/'
export const API_LOGOUT = '/v1/rest-auth/logout/'
export const API_GET_USER = '/v1/users/me/'
export const API_GET_USER_SETTINGS = '/v1/user-settings/'
export const LOGIN_PATH = '/login'
export const ACC_GROUP = 'accountants'
export const ACC_GROUP_NAME = 'Accountant'
export const SP_GROUP = 'service_providers'
export const SP_GROUP_NAME = 'Service Provider'
export const NONE_GROUP = 'None'
export const NONE_GROUP_NAME = 'Admin'
export const API_PASS_RESET = '/v1/password_reset/confirm/'
export const API_PASS_RESET_EMAIL = '/v1/password_reset/'
export const PASS_RESET_PATH = '/password-reset'
export const PASS_RESET_SUCCESS_MSG = 'OK'
export const ERR_EMAIL_REQUIRED = 'Email is required'
export const ERR_USER_REQUIRED = 'Username is required'
export const ERR_PASS_REQUIRED = 'Password is required'
export const ERR_PASS_CONFIRM_REQUIRED = 'This field is required'
export const ERR_PASS_MISMATCH = 'Passwords do not match'
export const ERR_WRONG_CREDS = 'Wrong username/password combination'
export const ERR_LOGIN = 'Error trying to log in. Please try again'
export const ERR_LOGOUT = 'Error trying to end user session. Please try again'
export const ERR_USER_NOT_FOUND =
  'An error has occurred trying to get the user information'
export const ERR_NETWORK_NAME = 'Network Error'
export const ERR_PASS_RESET =
  'An error has occurred trying to reset the password'
export const ERR_NETWORK_MSG =
  'Server unreachable. Please check your Internet connection'

// Invoices
export const INVOICES_PATH = '/invoices'
export const API_INVOICE_ENDPOINT = '/v1/invoices/'
export const INVOICES_VIEW_NAME = 'Invoices'
export const INVOICES_OPTIONS_EXPIRED_TEXT = 'Early Pay Options Expired'
export const INVOICES_OPTIONS_SELECT_TEXT = 'Select Early Pay Option'

// Service Providers
export const SP_PATH = '/service-providers'
export const API_SERVICE_PROVIDERS_ENDPOINT = '/v1/users/service-providers/'
export const API_PAYMENT_TERMS_ENDPOINT = '/v1/payment-terms/'
export const ERR_SP_LIST =
  'An error has occurred trying to get the Service Provider information'
export const ERR_SP_SEARCH =
  'An error has occurred trying to search Service Providers'
export const ERR_SP_GET =
  'An error has occurred trying to get the Service Provider'
export const ERR_DELETE_GET =
  'An error has occurred trying to delete the Service Provider'
export const ERR_SAVING_SP =
  'An error has occurred trying to save the Service Provider'
export const ERR_SAVING_SP_PROFILE =
  'An error has occurred trying to save the profile information'
export const SUCCESS_SAVING_SP = 'Service Provider has been saved'
export const SUCCESS_SAVING_SP_PROFILE = 'Profile information saved'
export const SUCCESS_PASS_RESET =
  'An email has been sent with the instructions to reset your password'
export const SUCCESS_PASS_CHANGE =
  'Your password has been changed. You may now login'

// Announcements
export const API_ANNOUNCEMENTS_URL_PATH = '/v1/announcements/'
