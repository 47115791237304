import React, { lazy, Suspense } from 'react'

// Libs
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

// Services
import { importFailAction } from 'Services/Helper'

// Styles
import 'Assets/css/bootstrap.css'
import 'Assets/css/bootstrap-extended.css'
import 'Assets/css/components.css'
import 'Components/App/App.scss'

// Components
import { Spin } from 'antd'
import { LOGIN_PATH, PASS_RESET_PATH } from 'Services/Constants'

// Components (Lazy)
const Login = lazy(() => import('Components/Login').catch(importFailAction))
const PasswordReset = lazy(() =>
  import('Components/PasswordReset').catch(importFailAction),
)
const Layout = lazy(() => import('Components/Layout').catch(importFailAction))
const PrivateRoute = lazy(() =>
  import('Routes/PrivateRoute').catch(importFailAction),
)
const GuestRoute = lazy(() =>
  import('Routes/GuestRoute').catch(importFailAction),
)

const App = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <Spin
            size="large"
            tip="Loading..."
            className="fullscreen-loader d-flex flex-column justify-content-center align-items-center"
          />
        }
      >
        <Switch>
          <GuestRoute exact path={LOGIN_PATH} redirect="/" component={Login} />
          <GuestRoute
            exact
            path={PASS_RESET_PATH}
            redirect="/"
            component={PasswordReset}
          />
          <PrivateRoute path="/" redirect={LOGIN_PATH} component={Layout} />
          <Route render={() => <Redirect to={LOGIN_PATH} />} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
