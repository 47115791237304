import React from 'react'
import ReactDOM from 'react-dom'

// Components
import App from 'Components/App'

// Services
import { ENV_DEV_NAME } from 'Services/Constants'

// Libs
import * as Sentry from '@sentry/react'
import reportWebVitals from './reportWebVitals'

/**
 * Enables Sentry for other than development
 */
if (process.env.REACT_APP_ENV !== ENV_DEV_NAME)
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
