// Libs
import * as Sentry from '@sentry/react'

// Standard table options
export const tableOptions = {
  size: 'middle',
  pagPos: 'bottom',
  pageSize: 40,
  bordered: true,
}

/**
 * Stores value in Local Storage
 * @param {String} key
 * @param {String} val
 */
export const setStorage = (key, val) => {
  localStorage.setItem(key, val)
}

/**
 * Gets value from Local Storage
 * @param {String} key
 */
export const getStorage = (key) => {
  return localStorage.getItem(key)
}

/**
 * Removes from Local Storage
 * @param {String} key
 */
export const removeStorage = (key) => {
  localStorage.removeItem(key)
}

/**
 * Clears all Local Storage
 */
export const clearStorage = () => {
  localStorage.clear()
}

/**
 * Sends a string message to Sentry
 * @param {String} params.msg message to be sent to Sentry
 */
export const informSentry = ({ msg }) => {
  Sentry.captureMessage(msg)
}

/**
 * Defines an action to handle failed lazy imports
 */
export const importFailAction = () => window.location.reload()
